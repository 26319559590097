import Slot from "./Slot";
import Row from "react-bootstrap/Row";

function CompSub({ editMode, encounterInfo, compSubInfo, players, roles, highlightedPlayers, updateCompSub }) {
    if (!compSubInfo) {
        return null;
    }

    return (
        <Row style={{marginTop: "5px"}}>
            {compSubInfo.map((slot, index) => {
                return (
                    <Slot
                        key={index}
                        editMode={editMode}
                        encounterInfo={encounterInfo}
                        slotInfo={slot}
                        players={players}
                        roles={roles}
                        highlightedPlayers={highlightedPlayers}
                        updateComp={(newRoleId, newPlayerId, newExtraArray) => updateCompSub(index, newRoleId, newPlayerId, newExtraArray)}
                    />
                );
            })}
        </Row>
    );
}

export default CompSub;