import './App.css';
import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";
import Comp from "./routes/Comp";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div>
            GGtK Raid Comp
          </div>
        </header>

        <Comp />
      </div>
    </Router>
  );
}

export default App;
