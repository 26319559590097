import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

function Player({ player, highlighted, playerClicked }) {
    return (
        <Col xs={6} md={4} lg={3} xl={2} style={{ marginTop: "5px", marginBottom: "5px" }}>
            <Button variant={`${highlighted ? "success" : "secondary"}`} style={{ width: "100%" }} onClick={playerClicked}>{player}</Button>
        </Col>
    )
}

export default Player;