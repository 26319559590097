import CompSub from "./CompSub";
import Container from "react-bootstrap/Container";

function Encounter({ editMode, encounterInfo, compInfo, players, roles, highlightedPlayers, updateComps }) {
    return (
        <div style={{ marginTop: "10px" }}>
            <div>
                {encounterInfo.name}
            </div>
            <Container>
                {
                    compInfo ?
                        compInfo.map((sub, index) => {
                            return (
                                <CompSub
                                    key={index}
                                    editMode={editMode}
                                    encounterInfo={encounterInfo}
                                    compSubInfo={sub}
                                    players={players}
                                    roles={roles}
                                    highlightedPlayers={highlightedPlayers}
                                    updateCompSub={(playerIndex, newRoleId, newPlayerId, newExtraArray) => updateComps(index, playerIndex, newRoleId, newPlayerId, newExtraArray)}
                                />)
                            ;
                        })
                        : null
                }
            </Container>
        </div>

    );
}

export default Encounter;