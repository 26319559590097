import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import { Form } from "react-bootstrap";
import Select from 'react-select'

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: "black",
    }),
    control: (provided) => ({
        ...provided
    }),
    singleValue: (provided, state) => {
        return { ...provided };
    }
}

function Slot({ editMode, encounterInfo, slotInfo, players, roles, highlightedPlayers, updateComp }) {
    let encounterRoles = (encounterInfo.roles || []);
    let formattedEncounterRoles = encounterRoles.map((role) => {
        return {
            value: role,
            label: role
        }
    });

    let roleId = slotInfo["roleId"];

    let playerId = slotInfo["playerId"];

    let extraRoles = slotInfo["extra"] || [];
    for (let i = extraRoles.length - 1; i >= 0; i--) {
        if (!encounterRoles.includes(extraRoles[i])) {
            extraRoles.splice(i, 1);
        }
    }
    let formattedExtraRoles = extraRoles.map((role) => {
        return {
            value: role,
            label: role
        }
    });

    let updateRoleId = (newRoleId) => {
        updateComp(newRoleId, playerId, extraRoles);
    }

    let updatePlayerId = (newPlayerId) => {
        updateComp(roleId, newPlayerId, extraRoles);
    }

    let updateExtras = (newExtraRoles) => {
        let newFormattedExtraRoles = newExtraRoles.map((role) => {
            return role.value;
        });
        updateComp(roleId, playerId, newFormattedExtraRoles);
    }

    return (
        <Col style={{
            border: `1px solid ${highlightedPlayers.has(playerId) ? "orange" : "white"}`, display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            color: `${highlightedPlayers.has(playerId) ? "orange" : "white"}`,
            padding: "3px"
        }}
        >
            {
                editMode ?
                    (
                        <>
                            <Form.Control
                                as="select"
                                onChange={(e) => updateRoleId(e.target.value)}
                                value={roleId ?? ""}
                            >
                                <option value={""}>Select a role</option>
                                {roles.map((role, roleIndex) => {
                                    return (
                                        <optgroup label={role.label} key={roleIndex}>
                                            {role.subRoles.map((subRole) => {
                                                return (
                                                    <option key={subRole} value={subRole}>{subRole}</option>
                                                );
                                            })}
                                        </optgroup>
                                    );
                                })}

                            </Form.Control>

                            <Select
                                className="w-100"
                                style={{width: "100%"}}
                                isMulti
                                styles={customStyles}
                                options={formattedEncounterRoles}
                                value={formattedExtraRoles}
                                onChange={updateExtras}
                                closeMenuOnSelect={false}
                            />

                            <Form.Control
                                as="select"
                                onChange={(e) => updatePlayerId(e.target.value)}
                                value={playerId ?? ""}
                            >
                                <option value={""}>Select a player</option>
                                {players.map((player) => {
                                    return (
                                        <option key={"3" + player} value={player}>{player}</option>
                                    );
                                })}
                            </Form.Control>
                        </>
                    ) :
                    (
                        <>
                            <small style={{ marginBottom: "10px" }}>{roleId} </small>

                            {
                                Array.isArray(extraRoles) && extraRoles.length > 0 ?
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                        {extraRoles.map((extraRole, index) => {
                                            return (
                                                <Badge key={index} pill variant="light" style={{ marginRight: "3px", marginBottom: "4px",  backgroundColor: `${highlightedPlayers.has(playerId) ? "orange" : ""}` }}>{extraRole}</Badge>
                                            );
                                        })}
                                    </div>
                                    : null
                            }

                            <strong>{playerId}</strong>
                        </>
                    )
            }
        </Col>
    );
}

export default Slot;