import { useEffect, useState } from "react";
import Wing from "./Wing";
import Players from "./Players";
import { Button } from "react-bootstrap";
import _ from "lodash";
import socketIOClient from "socket.io-client";

const players = [
    "ANY",
    "Keo",
    "Gumby",
    "Zuri",
    "Sally",
    "Fish",
    "Max",
    "Pooh",
    "Nguyen",
    "Teddy",
    "Nifty",
    "Serasilla"
];
players.sort();

const roles = [
    {
        label: "Support",
        subRoles: [
            "Boon + Heal Herald",
            "DPS Transfusion Scourge",
            "Banner Warrior",
            "Druid",
            "Heal Scourge",
            "Condi Druid"
        ]
    },
    {
        label: "Quickness",
        subRoles: [
            "Quickness",
            "Power Quickness",
            "Quickbrand",
            "Quickness Scrapper",
            "STM Chrono",
            "Inspi Chrono",
            "Healbrand",
            "Heal Scrapper",
        ]
    },
    {
        label: "Alacrity",
        subRoles: [
            "Alacrity (solo)",
            "Alacrity (duo)",
            "Power Alacren (solo)",
            "Celestial Renegade (solo)",
            "RR Ren (duo)",
            "Staff Mirage (solo)",
            "Staff Mirage (duo)",
        ]
    },
    {
        label: "DPS",
        subRoles: [
            "DPS",
            "Power DPS",
            "Condi DPS",
            "DPS Soulbeast",
        ]
    },
];

const wings = {
    1: { name: "Forsaken Thicket: Spirit Vale", backgroundURL: "https://wiki.guildwars2.com/images/thumb/9/97/Spirit_Vale_loading_screen.jpg/320px-Spirit_Vale_loading_screen.jpg" },
    2: { name: "Forsaken Thicket: Salvation Pass", backgroundURL: "https://wiki.guildwars2.com/images/thumb/2/23/Salvation_Pass_loading_screen.png/320px-Salvation_Pass_loading_screen.png" },
    3: { name: "Forsaken Thicket: Stronghold of the Faithful", backgroundURL: "https://wiki.guildwars2.com/images/thumb/8/87/Stronghold_of_the_Faithful_loading_screen.jpg/320px-Stronghold_of_the_Faithful_loading_screen.jpg" },
    4: { name: "Bastion of the Penitent", backgroundURL: "https://wiki.guildwars2.com/images/thumb/2/2d/Bastion_of_the_Penitent_loading_screen.jpg/320px-Bastion_of_the_Penitent_loading_screen.jpg" },
    5: { name: "Hall of Chains", backgroundURL: "https://wiki.guildwars2.com/images/thumb/8/89/Hall_of_Chains_loading_screen.jpg/320px-Hall_of_Chains_loading_screen.jpg" },
    6: { name: "Mythwright Gambit", backgroundURL: "https://wiki.guildwars2.com/images/thumb/5/52/Mythwright_Gambit_loading_screen.jpg/320px-Mythwright_Gambit_loading_screen.jpg" },
    7: { name: "The Key of Ahdashim", backgroundURL: "https://wiki.guildwars2.com/images/thumb/2/2d/The_Key_of_Ahdashim_loading_screen.jpg/320px-The_Key_of_Ahdashim_loading_screen.jpg" },
};

const wingEncounters = {
    1: {
        1: { name: "Vale Guardian", roles: ["Tank", "Condi", "Seeker Control"] },
        2: { name: "Gorseval the Multifarious", roles: ["Tank"] },
        3: { name: "Sabetha the Saboteur", roles: ["Kite", "1/3 Cannon", "2/4 Cannon", "Backup Cannon"] }
    },
    2: {
        1: { name: "Slothasor", roles: ["Stability", "Mushroom 1", "Mushroom 2", "Mushroom 3", "Mushroom 4"] },
        2: { name: "Prison Camp", roles: ["Mortar", "Saboteur"] },
        3: { name: "Matthias Gabrel", roles: ["Reflect"] }
    },
    3: {
        1: { name: "Keep Construct", roles: ["Tank", "Orb Push"] },
        2: { name: "Xera", roles: ["Tank"] }
    },
    4: {
        1: { name: "Cairn the Indomitable", roles: [] },
        2: { name: "Mursaat Overseer", roles: ["Claim", "Dispel", "Protect", "Epi"] },
        3: { name: "Samarog", roles: ["1+ Utility CC", "Mace/Mace"] },
        4: { name: "Deimos", roles: ["Tank", "Kite", "North Chain", "South Chain", "West Chain", "East Chain"] }
    },
    5: {
        1: { name: "Soulless Horror", roles: ["Tank", "Push", "1+ Utility CC", "Epi"] },
        2: { name: "River of Souls", roles: ["Superspeed", "Aegis"] },
        3: { name: "Statues of Grenth (Broken King)", roles: ["Tank"] },
        4: { name: "Statues of Grenth (Eater of Souls)", roles: ["Tank", "Sac", "Green"] },
        5: { name: "Statues of Grenth (Eye of Judgement)", roles: ["Orb Throw", "Orb Stun"] },
        6: { name: "Dhuum", roles: ["Tank", "Kite", "G1", "G2", "G3"] },
    },
    6: {
        1: { name: "Conjured Amalgamate", roles: ["Sword 1", "Sword 2", "Shield 1", "Shield 2", "Projectile Handling"] },
        2: { name: "Twin Largos", roles: ["Tank", "Portal"] },
        3: { name: "Qadim", roles: ["Tank (Primary)", "Tank (Wyvern)", "Patriarch", "Matriarch", "Kite", "Lamp 1", "Lamp 2", "Lamp 3", "Open Lamp", "Remove Stability"] }
    },
    7: {
        1: { name: "Cardinal Sabir", roles: ["Tank", "Portal", "Wisp Control", "Projectile Handling", "Epi"] },
        2: { name: "Cardinal Adina", roles: ["Tank", "Pillar Kite", "Stability", "Projectile Handling"] },
        3: { name: "Qadim the Peerless", roles: ["Tank", "Kite", "N Pylon", "SW Pylon", "SE Pylon", "Anomaly CC"] }
    }
};

function Comp() {
    let [wingEncounterComps, setWingEncounterComps] = useState(null);
    let [highlightedPlayers, setHighlightedPlayers] = useState(new Set([]));
    let [canEdit, setCanEdit] = useState(false);
    let [editMode, setEditMode] = useState(false);
    let [socket, setSocket] = useState(null);

    useEffect(() => {
        let newSocket = socketIOClient("https://api.gw2.kalemaplesyrup.com");
        setSocket(newSocket);

        newSocket.on("currentComps", data => {
            setWingEncounterComps(data);
        });
        newSocket.on("updateWingEncounterComps", ({ wing, encounter, sub, playerIndex, newRoleId, newPlayerId, newExtraArray }) => {
            setWingEncounterComps((currentWingEncounterComps) => {
                let newWingEncounterComps = _.cloneDeep(currentWingEncounterComps);
                let slotToUpdate = newWingEncounterComps[wing][encounter][sub][playerIndex];

                slotToUpdate["roleId"] = newRoleId;
                slotToUpdate["playerId"] = newPlayerId;
                slotToUpdate["extra"] = newExtraArray;

                return newWingEncounterComps;
            });
        });
        newSocket.emit("checkCanEdit", "", (ack) => {
            setCanEdit(ack);
        });

        return () => newSocket.disconnect();
    }, []);

    let updateWingEncounterComps = (wing, encounter, sub, playerIndex, newRoleId, newPlayerId, newExtraArray) => {
        if (socket) {
            socket.emit("updateWingEncounterComps", { wing, encounter, sub, playerIndex, newRoleId, newPlayerId, newExtraArray });
        }
    };

    return (
        <div>
            {
                canEdit ?
                    <Button style={{ marginRight: "4px" }} onClick={() => setEditMode(!editMode)}>{editMode ? "Disable" : "Enable"} Edit</Button> :
                    null
            }

            <Players players={players} highlightedPlayers={highlightedPlayers} setHighlightedPlayers={setHighlightedPlayers} />

            {
                wingEncounterComps ?
                    <div>
                        {Object.entries(wings).map(([key, value]) => {
                            return (
                                <Wing
                                    key={key}
                                    index={key}
                                    editMode={editMode}
                                    wingInfo={value}
                                    encounters={wingEncounters[key]}
                                    comps={wingEncounterComps[key]}
                                    players={players}
                                    roles={roles}
                                    highlightedPlayers={highlightedPlayers}
                                    updateEncounterComps={(encounter, sub, playerIndex, newRoleId, newPlayerId, newExtraArray) => updateWingEncounterComps(key, encounter, sub, playerIndex, newRoleId, newPlayerId, newExtraArray)}
                                />
                            );
                        })}
                    </div> :
                    null
            }

            <div style={{ height: "20px" }}>

            </div>
        </div>

    );
}

export default Comp;