import Encounter from "./Encounter";
import Container from "react-bootstrap/Container";
import { useState } from "react";

function Wing({ index, editMode, wingInfo, encounters, comps, players, roles, highlightedPlayers, updateEncounterComps }) {
    let [expanded, setExpanded] = useState(false);
    return (
        <Container style={{ marginTop: "20px", padding: "0", backgroundImage: `url('${wingInfo.backgroundURL}')`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
            <div style={{ backgroundColor: "rgb(128,128,128,0.75)" }}>
                <div style={{ cursor: "pointer" }} onClick={() => { setExpanded(!expanded) }}>
                    <u><strong>W{index} - {wingInfo.name}</strong></u>
                </div>

                {
                    expanded && encounters ?
                        Object.entries(encounters).map(([key, value]) => {
                            return (
                                <Encounter
                                    key={key}
                                    editMode={editMode}
                                    encounterInfo={value}
                                    compInfo={comps[key]}
                                    players={players}
                                    roles={roles}
                                    highlightedPlayers={highlightedPlayers}
                                    updateComps={(sub, playerIndex, newRoleId, newPlayerId, newExtraArray) => updateEncounterComps(key, sub, playerIndex, newRoleId, newPlayerId, newExtraArray)}
                                />
                            );
                        })
                        : null
                }
            </div>

        </Container>
    );
}

export default Wing;