import { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Player from "./Player";

function Players({ players, highlightedPlayers, setHighlightedPlayers }) {
    let [expanded, setExpanded] = useState(true);

    let handlePlayerClicked = (id) => {
        let newHighlightedPlayers = new Set(highlightedPlayers);

        if (newHighlightedPlayers.has(id)) {
            newHighlightedPlayers.delete(id);
        } else {
            newHighlightedPlayers.add(id);
        }

        setHighlightedPlayers(newHighlightedPlayers);
    }

    return (
        <Container style={{ border: "1px solid white", padding: "0" }}>
            <div style={{ cursor: "pointer" }} onClick={() => { setExpanded(!expanded) }}>
                Players (Select for highlight)
            </div>

            {
                expanded ?
                    <Container>
                        <Row>
                            {players.map((player, index) => {
                                return (
                                    <Player key={index} player={player} highlighted={highlightedPlayers.has(player)} playerClicked={() => { handlePlayerClicked(player) }} />
                                );
                            })}
                        </Row>
                    </Container>
                    : null
            }

        </Container>
    );
}

export default Players;